import React from 'react';
import {useSpring, animated} from 'react-spring'

const Hero = () => {

    const fadeHero = useSpring({ 
        from: { opacity: 0},
        delay: 600,
        opacity: 1,
        config: {duration : 500} 
      })    


    return (
        

        <animated.div  className="heroContainer" style={fadeHero} >
            <p className="hero-copy">
            Hi there. I'm Ridwan Sanusi, an experience designer that codes, focusing on creating systems and facilitating cohesive software experiences. Currently living in Toronto, Canada. <a href="https://read.cv/ridwansanusi" target="_blank" className="">More about me</a>
            </p>
        </animated.div>  

    )
}

export default Hero;